import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"

import Logo from "../images/logo-v1.png"
import Globe from "../images/globe-ilustrator.png"
import Bolt from "../images/bolt.svg"

export default function Home(props) {
  return (
    <Layout headerVisible="false">
      <div className="banner" style={{ backgroundColor: "#140e3a" }}>
        <div className="">
          <nav
            className="
            navbar
            active_nav
            navbar-expand-lg navbar-light
            py-md-0
            mob-pad
            fixed-top
          "
          >
            <Link className="navbar-brand" to="/">
              <img src={Logo} alt="Business Logo" width="200" className="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ml-auto landing-page">
                <li className="nav-item mx-md-4 my-md-0 postion-relative">
                  <Link className="nav-link px-0 active" to="/">
                    Home
                  </Link>
                </li>

                <li className="nav-item mx-md-4 my-md-0 postion-relative">
                  <Link className="nav-link px-0" to="#about">
                    About
                  </Link>
                </li>

                <li className="nav-item mx-md-4 my-md-0 postion-relative">
                  <Link className="nav-link px-0" to="#packages">
                    Packages
                  </Link>
                </li>

                <li className="nav-item mx-md-4 my-md-0 postion-relative">
                  <Link className="nav-link px-0" to="#benefits">
                    Benefits
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="container">
          <div
            className="row mt-lg-5 justify-content-center mt-4"
            style={{ alignItems: "center" }}
          >
            <div className="col-lg-6 h-100 my-3">
              <div className="ova-heading-content mt-4">
                <h2 className="ova-heading ova_before">
                  <span className="ova_before"></span>
                  BUSINESSIQ AFRICA INVESTMENT CLUB
                </h2>

                <p className="ova-heading-description">
                  The rich pay themselves in investments and dividends, not
                  salaries.
                </p>

                <p className="heading-description">
                  The BusinessIQ Africa Investment Club helps you invest your
                  money in secured investments using the principle of compound
                  interest by investing in subscription and real estate
                  businesses to multiply and grow your money by 300% or more.
                  And gives you returns on investment every quarter for a
                  lifetime.
                </p>
              </div>

              <Link
                to="/register"
                className="btn btn--primary px-5 rounded-0 mt-lg-5 mt-4"
              >
                Sign Up
              </Link>
            </div>

            <div className="col-lg-6 h-100 my-3 text-right order-first order-lg-last">
              <img
                width="649"
                height="626"
                src={Globe}
                className="attachment-full img-fluid my-5 my-3"
                alt=""
                loading="lazy"
                //   srcset="
                //   img/globe-ilustrator.png 649w,
                //   img/globe-ilustrator.png 300w
                // "
                sizes="(max-width: 649px) 100vw, 649px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="SpadeQueen__two" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <p>
                The middle class work very hard to earn salaries that pay for
                their living expenses while the rich focus on investments that
                will yield constant passive income that will pay for their life
                of luxury. You go through stress for two major reasons: One, you
                don’t have constant streams of passive income, and two, you
                spend your money on things that don’t appreciate. But the rich
                spend their money on things that will create more money for them
                so they can live life without stress. That is the circle where
                you should be.
              </p>

              <p>
                It is not too late to join the league of the rich who are not
                struggling to pay house rents, school fees, buy properties or
                travel abroad. Start by being part of the BusinessIQ Africa
                Investment Club.
              </p>

              <p className="text-dark">
                We do not invest in volatile investments like crypto-currencies
                or forex. The BusinessIQ Africa Investment Club allows you to
                invest alongside other intelligent investors in secured
                investments. At BusinessIQ Africa Investment Club, we invest
                jointly in luxury rental properties in Lagos, Abuja, Durban
                (South Africa), and Dubai (UAE); properties that chunk out
                monthly, quarterly and yearly returns. And subscription
                businesses that chunk out monthly returns. We believe our
                investors work hard for their money, and it is our obligation to
                protect and give them returns on their investment as at when
                due.
              </p>
              <h2 className="my-3">How to sign up</h2>
              <p>
                Click any of the packages below. Fill in your information. Make
                a transfer of investment capital into the BusinessIQ Africa
                Corporate Account that will pop up. Once payment is received,
                you will receive an email of confirmation from BusinessIQ
                Investment Club alongside the Investment Agreement and
                Certificate of Investment.
              </p>

              <p>
                To get started, click sign up on any of the investment packages.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <h2 className="my-3">Investment Packages</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="section pricingcartsection" id="packages">
        <div className="container-fluid pricingcartcontainer">
          <div className="pricingcartwrap">
            <div className="pricingcart free">
              <div className="pricingcartdetail">
                <div className="pricingcartcontentwrap">
                  <div className="pricingcartbadge freebadge">
                    <div>SMART INVESTOR</div>
                  </div>
                  <div className="row">
                    <div id="freePrice" className="pricingcartprice">
                      ₦500,000 to ₦5,000,000
                    </div>
                  </div>
                  <div className="pricingcartinfowrap">
                    <div className="pricingcartinfo">
                      As a SMART INVESTOR, in the first six month, you will get
                      your capital back.
                    </div>
                  </div>
                </div>
                <Link
                  to="register/smart_investor"
                  className="pricingcartbutton w-inline-block"
                >
                  Sign Up
                </Link>
              </div>

              <div className="pricingcartincluded">
                <div className="pricingcartincludedtitle">What’s included</div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    data-w-id="f23ac2b4-12ef-e473-82b8-8aac4af3bb07"
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    Every quarter (EVERY 3 MONTHS), you will be receiving 10% of
                    your invested capital for a period of 3 years.
                  </div>
                </div>

                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    After 3 years, Investment will be terminated and you will
                    have to reinvest your capital.
                  </div>
                </div>
              </div>
            </div>

            <div className="pricingcart essentials">
              <div className="pricingcartdetail">
                <div className="pricingcartcontentwrap">
                  <div className="pricingcartbadge essentialsbadge">
                    <div>PREMIUM INVESTOR</div>
                  </div>
                  <div className="row">
                    <div id="essentialsPrice" className="pricingcartprice">
                      ₦5,000,000 to ₦50,000,000
                    </div>
                  </div>
                  <div className="pricingcartinfowrap">
                    <div className="pricingcartinfo">
                      As a PREMIUM INVESTOR, in the first six month, you will
                      get your capital back.
                    </div>
                  </div>
                </div>
                <Link
                  to="/register/premium_investor"
                  className="pricingcartbutton bluecartbutton w-inline-block"
                >
                  Sign Up
                </Link>
              </div>
              <div className="pricingcartincluded">
                <div className="pricingcartincludedtitle">WHAT’S INCLUDED</div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    data-w-id="0b41097c-86c3-294c-5cd7-325680f2b7dd"
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    Every quarter (EVERY 3 MONTHS), you will be receiving 10% of
                    your invested capital for a period of 4 years.
                  </div>
                </div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    data-w-id="0b41097c-86c3-294c-5cd7-325680f2b7e1"
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    After 4 years, your investment will be terminated and you
                    will have to reinvest your capital.
                  </div>
                </div>

                <div className="includedtextwrap"></div>
              </div>
            </div>

            <div className="pricingcart enterprise">
              <div className="pricingcartdetail">
                <div className="pricingcartcontentwrap">
                  <div className="pricingcartbadge enterprisebadge">
                    <div>PRESTIGIOUS PARNTER</div>
                  </div>
                  <div className="row">
                    <div id="enterprisePrice" className="pricingcartprice">
                      ₦50,000,000 to ₦500,000,000
                    </div>
                  </div>
                  <div className="pricingcartinfowrap">
                    <div className="pricingcartinfo">
                      As a PRESTIGIOUS PARTNER, you are a joint owner of the
                      BusinessIQ Africa Investment Club and all the investments
                      owned by the Club.
                    </div>
                  </div>
                </div>
                <Link
                  to="/register/prestigious_partner"
                  className="pricingcartbutton w-inline-block"
                >
                  Sign Up
                </Link>
              </div>
              <div className="pricingcartincluded">
                <div className="pricingcartincludedtitle">WHAT’S INCLUDED</div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    data-w-id="e6eb8355-e429-443a-4169-5398f2431c98"
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    You will receive monthly reports on all investments and
                    monthly financial reports.
                  </div>
                </div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    data-w-id="e6eb8355-e429-443a-4169-5398f2431c9c"
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    You are also entitled to 20% on investment on all the
                    investments owned by the Club.
                  </div>
                </div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div className="pricingcartincludedtext">
                    This partnership is a lifetime partnership
                  </div>
                </div>
              </div>
            </div>

            <div className="pricingcart free">
              <div className="pricingcartdetail">
                <div className="pricingcartcontentwrap">
                  <div className="pricingcartbadge freebadge">
                    <div>LIFETIME PARTNER</div>
                  </div>
                  <div className="row">
                    <div id="freePrice" className="pricingcartprice">
                      ₦500,000,000 to ₦1,000,000,000
                    </div>
                  </div>
                  <div className="pricingcartinfowrap">
                    <div className="pricingcartinfo">
                      As a LIFETIME PARTNER, you are a joint owner of the
                      BusinessIQ Africa Investment Club and all the investments
                      owned by the Club.
                    </div>
                  </div>
                </div>
                <Link
                  to="register/lifetime_partner"
                  className="pricingcartbutton w-inline-block"
                >
                  Get Started
                </Link>
              </div>
              <div className="pricingcartincluded">
                <div className="pricingcartincludedtitle">What’s included</div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    data-w-id="f23ac2b4-12ef-e473-82b8-8aac4af3bb07"
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    You will receive monthly reports on all investments and
                    monthly financial reports.
                  </div>
                </div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div className="pricingcartincludedtext">
                    You are also entitled to 30% returns on all the investments
                    owned by the Club.
                  </div>
                </div>
                <div className="includedtextwrap">
                  <div className="check smallcheck"></div>
                  <div
                    data-w-id="f7d884fb-597b-7a98-9d8f-ba87a3a91ca9"
                    style={{ borderColor: "rgba(66, 66, 203, 0.3)" }}
                    className="pricingcartincludedtext"
                  >
                    This partnership is a lifetime partnership.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5" id="benefits">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <h2 className="my-3">Investment Benefits</h2>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-lg-3 my-3">
            <div className="benefit-card p-3 text-center h-100">
              <div className="pricingfaqicon mx-auto mb-3">
                <img src={Bolt} loading="lazy" alt="Lightning bolt icon" />
              </div>

              <p>Opportunity to earn constant passive income without hassle</p>
            </div>
          </div>

          <div className="col-lg-3 my-3">
            <div className="benefit-card p-3 text-center h-100">
              <div className="pricingfaqicon mx-auto mb-3">
                <img src={Bolt} loading="lazy" alt="Lightning bolt icon" />
              </div>

              <p>
                Privilege of investing alongside the very rich and people that
                matter
              </p>
            </div>
          </div>

          <div className="col-lg-3 my-3">
            <div className="benefit-card p-3 text-center h-100">
              <div className="pricingfaqicon mx-auto mb-3">
                <img src={Bolt} loading="lazy" alt="Lightning bolt icon" />
              </div>

              <p>
                Become part owner of luxury properties in Nigeria, South Africa
                and UAE
              </p>
            </div>
          </div>

          <div className="col-lg-3 my-3">
            <div className="benefit-card p-3 text-center h-100">
              <div className="pricingfaqicon mx-auto mb-3">
                <img src={Bolt} loading="lazy" alt="Lightning bolt icon" />
              </div>

              <p>Securing your future and that of your children</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section trialsection">
        <div className="container trialcontainer">
          <div className="row fullwidth">
            <div className="trialcontent">
              <div className="trialcontenttitle text-white">Contact Us</div>
              <div className="text-white font-weight-bold font-italic">
                Simplinteriors Place
              </div>
              <div className="text-white font-weight-bold font-italic">
                71 Falolu Road Surulere, Lagos
              </div>
              <div className="text-white">
                <a
                  className="text-white font-weight-bold font-italic"
                  href="mailto:investmentclub@businessiqafrica.com 
              "
                >
                  investmentclub@businessiqafrica.com
                </a>
              </div>
              <div className="text-white">
                <a
                  className="text-white font-weight-bold font-italic"
                  href="www.businessiqafrica.com/investmentclub"
                >
                  www.businessiqafrica.com/investmentclub
                </a>
              </div>
              <div className="text-white">
                <a
                  className="text-white font-weight-bold font-italic"
                  href="tel:+2348023157234"
                >
                  08023157234
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}
